import {notifications} from "@mantine/notifications";
import {IconCheck} from "@tabler/icons-react";
import {rem} from "@mantine/core";
import React from "react";
import {modals} from '@mantine/modals';

export const ALERT_MODAL = "ALERT_MODAL"

export const useAlert = () => {

    const showSuccessNotification = (message: string) => {
        notifications.show({
            color: 'teal',
            message: message,
            icon: <IconCheck style={{width: rem(18), height: rem(18)}}/>,
            autoClose: 2000,
        });
    }

    const showFailNotification = (message: string, description?: string) => {
        modals.openContextModal({
            modal: ALERT_MODAL,
            centered: true,
            withCloseButton: false,
            innerProps: {
                message: message,
                description: description,
            },
        })
    }

    return {showSuccessNotification, showFailNotification};
};
