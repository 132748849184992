import instance from "./index";
import {IApiResponse} from "../types/api-response";
import {ISignInRequest, ISignUpRequest} from "../types/member/request";
import {IRefreshTokenResponse, ISignInResponse} from "../types/member/response";

/**
 * 회원가입
 */
export const signUp = async (signUpRequest: ISignUpRequest): Promise<IApiResponse<null>> => {
    return await instance.post(`/members/sign-up`, {
        email: signUpRequest.email,
        password: signUpRequest.password,
    });
}

/**
 * 로그인
 */
export const signIn = async (signInRequest: ISignInRequest): Promise<IApiResponse<ISignInResponse>> => {
    return await instance.post(`/members/sign-in`, {
        email: signInRequest.email,
        password: signInRequest.password,
    });
}

/**
 * 리프레시 토큰 발급 요청
 */
export const refreshToken = async (): Promise<IApiResponse<IRefreshTokenResponse>> => {
    return await instance.post(`/members/refresh-token`);
}
