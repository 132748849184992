import {Box, Button, Text} from "@mantine/core";
import {ContextModalProps} from "@mantine/modals";

const AlertModal = ({
                        context,
                        id,
                        innerProps,
                    }: ContextModalProps<{ message: string, description: string }>) => (
    <Box p={'md'}>
        <Text ta='center' fw='bold' size='lg'>{innerProps.message}</Text>
        <Text ta='center' c={'gray.07'}>{innerProps.description}</Text>
        <Button mt={'md'} fullWidth variant={'default'} onClick={() => context.closeModal(id)}>확인</Button>
    </Box>
);

export default AlertModal;