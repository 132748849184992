import axios from 'axios';

const baseURL = `${process.env.REACT_APP_API_ENDPOINT}`;
const instance = axios.create({
    baseURL: baseURL,
    headers: {
        "Content-Type": "application/json; charset=utf-8",
    },
    withCredentials: true
});

export default instance;
