import {isRouteErrorResponse, useNavigate, useRouteError} from "react-router-dom";
import {Button, Container, Group, rem, Text, Title} from "@mantine/core";

export default function ErrorPage() {
    const error = useRouteError();
    const navigate = useNavigate();
    let errorMessage: string;

    if (isRouteErrorResponse(error)) {
        errorMessage = error.data?.message || error.statusText;
    } else if (error instanceof Error) {
        errorMessage = error.message;
    } else if (typeof error === 'string') {
        errorMessage = error;
    } else {
        console.error(error);
        errorMessage = 'Unknown error';
    }

    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    if (chunkFailedMessage.test(errorMessage)) {
        window.location.reload();
        return <></>;
    }

    const onCLickGoHome = () => {
        navigate('/');
    }

    return (
        <Container pt={rem(80)} pb={rem(80)}>
            <Title size={'lg'} fw={'bold'} ta={'center'} mb={'md'}>일시적으로 오류가 발생했습니다.</Title>
            <Text c="dimmed" size="lg" ta="center" mb={'xl'}>
                {errorMessage}
            </Text>
            <Group justify="center">
                <Button variant="subtle" size="md" onClick={onCLickGoHome}>
                    홈으로 돌아가기
                </Button>
            </Group>
        </Container>
    )
}
