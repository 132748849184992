import {create} from 'zustand'
import {createJSONStorage, devtools, persist} from 'zustand/middleware'
import {ISignInResponse} from "../types/member/response";

interface AuthState {
    accessToken: string;
    email: string;
    memberId: string;
    role: string;

    isLoggedIn: () => boolean;
    updateAccessToken: (accessToken: string) => void;
    setState: (data: ISignInResponse) => void;
    reset: () => void;
}

const initAuthState = {
    accessToken: '',
    email: '',
    memberId: '',
    role: '',
}

export const useAuthStore = create<AuthState>()(
    devtools(
        persist(
            (set, get) => ({
                ...initAuthState,
                isLoggedIn: () => get().memberId != '',
                setState: (data) => set((state) => ({...state, ...data})),
                updateAccessToken: (accessToken: string) => set((state) => ({...state, accessToken: accessToken})),
                reset: () => {
                    set(initAuthState)
                }
            }),
            {
                name: 'auth-storage', // name of the item in the storage (must be unique)
                storage: createJSONStorage(() => localStorage), // (optional) by default, 'localStorage' is used
            },
        ),
    )
)
