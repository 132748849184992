class PathV3 {
    private static instance: PathV3 | null;
    public static get Instance(): PathV3 {
        return this.instance ?? (this.instance = new this());
    }

    readonly MAIN = '/';
    readonly STOCKS = '/stocks';
    readonly PORTFOLIOS = '/portfolios';
    readonly REPORTS = '/reports';
    readonly SIGN_IN = '/members/sign-in';
    readonly SIGN_UP = '/members/sign-up';
}

export const Path = PathV3.Instance;
